




















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class EmployeeForgotPassword extends Vue {
  goToLogin() {
    this.$router.push({ name: 'login' })
  }
}
